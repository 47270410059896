/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-26 10:33:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/view/partner/apply/applyLeader/index.js
 */
import { queryConfig, saveConfig } from "@lib";
export default {
  data() {
    return {
      disabled: true,
      infoData: {
        token: "",
        orgAddUrl: "", //机构
        deptAddUrl: "", //部门
        orgDelUrl: "",
        orgUpdateUrl: "",
        roleAddUrl: "", //角色
        roleDelUrl: "",
        roleUpdateUrl: "",
        tenementAddUrl: "", //租户
        tenementDelUrl: "",
        tenementUpdateUrl: "",
        tenementRoleAddUrl: "", //租户应用授权
        tenementRoleDelUrl: "",
        tenementRoleUpdateUrl: "",
        userAddUrl: "", // yonhhu
        userDelUrl: "",
        userUpdateUrl: "",
        userFunctionAddUrl: "", //用户功能授权
        userFunctionDelUrl: "",
        userFunctionUpdateUrl: "",
        userRoleAddUrl: "", //用户角色授权
        userRoleDelUrl: "",
        userRoleUpdateUrl: "",
        //机构部门 ？？
      },
      applicationId: "",
      clientSecret: "",
      token: "",
    };
  },

  methods: {
    updateFn() {
      this.disabled = false;
    },
    async onSubmit() {
      try {
        let { status, result } = await saveConfig({
          ...this.infoData,
          applicationId: this.applicationId,
        });
        if (status === "success") {
          this.$message.success("保存成功");
          // this.$router.back();
          this.disabled = true;
          this.getInfo();
        } else {
          this.$message.error(result?.error_msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    //获取详情
    async getInfo() {
      try {
        let { status, result } = await queryConfig({
          applicationId: this.applicationId,
        });
        if (status === "success") {
          if (result[0]) {
            this.infoData = JSON.parse(JSON.stringify(result[0]));
            this.token = this.infoData.token;
          }
        } else {
          this.$message.error(result?.error_msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    let info = JSON.parse(this.routerParams.info);
    this.applicationId = info.id;
    this.clientSecret = info.clientSecret;
    this.getInfo();
  },
};
